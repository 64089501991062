import React, { useEffect } from 'react';
import './index.css'
// import { weddingDb, weddingStorage } from '../../../../firebase-wedding-camera-config';
// import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
// import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import Home from '../../home/index.tsx';

const WeddingCameraAndroid : React.FC = () => { 

  useEffect(() => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.mcode.weddingcam&hl=hr'
  }, [])



  return( <Home/>)
  
//   useEffect(() => {
//     const imagePicker = document.getElementById('imagePicker');
//      const loader = document.getElementById('loader'); 
//      const uploadButton = document.getElementById('image-upload-button'); 

//     if(imagePicker) {
//       imagePicker.addEventListener('change', function(event) {
//         const files = event.target.files;

//         console.log(files.length);

//         if (files.length > 5) {
//           alert('Maksimalno 5 slika');
//           imagePicker.value = '';
//           return;
//         }

//         loader.style.display = 'block'; 
//         uploadButton.style.display ='none'

//         // Create an array of promises for the image uploads
//         const uploadPromises = Array.from(files).map(file => {
//           return new Promise((resolve, reject) => {
//             uploadImage(file)
//               .then(resolve)  // Resolve when the upload is successful
//               .catch(reject); // Reject if there's an error
//           });
//         });

//         // Wait for all uploads to finish
//         Promise.all(uploadPromises)
//           .then(() => {
//             loader.style.display = 'none'; // Hide loader when uploads finish
//             uploadButton.style.display ='block'

//             alert('Slike su uspješno prenesene!');
//           })
//           .catch(error => {
//             loader.style.display = 'none'; // Hide loader when uploads finish
//             uploadButton.style.display ='block'

//             alert("Greška kod uploada slika")
//             console.error('Error uploading images:', error);
//           });
//       });
//     }
//   }, []);

//   function compressImage(file, quality, callback) {
//     const reader = new FileReader();

//     reader.readAsDataURL(file);
//     reader.onload = function(event) {
//       const img = new Image();

//       img.src = event.target.result;
//       img.onload = function() {
//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');

//         const maxWidth = 1080;
//         const scale = maxWidth / img.width;
//         canvas.width = maxWidth;
//         canvas.height = img.height * scale;

//         ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

//         canvas.toBlob(function(blob) {
//           const compressedFile = new File([blob], file.name, {
//             type: 'image/jpeg',
//             lastModified: Date.now(),
//           });
//           callback(compressedFile);
//         }, 'image/jpeg', quality);
//       };
//     };
//   }

//   const uploadImage = (image) => {
//     return new Promise((resolve, reject) => {
//       // const storageRef = ref(weddingStorage, `test/${new Date().getTime()}`);
//       const storageRef = ref(weddingStorage, `matea&dalibor-14.09.2024./${Math.random() * 10000000000000000}`);

//       compressImage(image, 0.8, function(compressedFile) {
//         uploadBytes(storageRef, compressedFile)
//           .then(async (snapshot) => {
//             const url = await getDownloadURL(storageRef)
//             console.log(snapshot);
//             storeWeddingPhoto(url)
//             resolve(); // Resolve the promise when upload is successful
//           })
//           .catch(error => {
//             console.error('Upload failed:', error);
//             reject(error); // Reject the promise if the upload fails
//           });
//       });
//     });
//   };

//   const uploadImageClick = () => {
//     const imagePicker = document.getElementById('imagePicker');
//     if (imagePicker) {
//       imagePicker.click();
//     }
//   };

  

//   const storeWeddingPhoto = async (url: string) => {
//     const dateNow = Date.now();
//     await addDoc(collection(weddingDb, 'Martina&Dino-21.09.2024.'), {
//       base64: null,
//       date: dateNow,
//       height: 1440,
//       width: 1080,
//       image: url,
//       uri: url
//     })
// };

//   return (
//     // <Home />
//     <div className='position-fixed'>
//       <div className='android-wedding-camera-container'/>

//       <div className='wedding-camera-text-container'>
//         <img className='wedding-camera-logo' src={require('../../../../assets/weddingCamera/logo-with-text.png')} />
       
//         {/* <p>
//           Naša aplikacija trenutno ima 
//           tehničkih poteškoća i nije dostupna 
//           za preuzimanje na Androidu. 
//         </p> */}

//         {/* <p>
//           Radimo na rješavanju problema. <br/>
//           Hvala vam na strpljenju i 
//           razumijevanju!
//         </p> */}
        
//         <h3>Marina & Dino</h3>

//         <p>
//           Da bi ste ostavili slike mladencima, <br/> prenesite ih ovdje:
//         </p>

//         <input type="file" id="imagePicker" accept="image/*" multiple></input>

//         <div id="loader" style={{display: 'none'}}>Prijenos slika u tijeku. Ne zatvarajte stranicu ...</div>

//         <button id='image-upload-button' onClick={uploadImageClick}>
//           Prenesi fotografiju
//           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M9.00003 6.79555H10.9795V15.8572C10.9795 15.975 11.0759 16.0714 11.1938 16.0714H12.8009C12.9188 16.0714 13.0152 15.975 13.0152 15.8572V6.79555H15C15.1795 6.79555 15.2786 6.5893 15.1688 6.45001L12.1688 2.6518C12.1487 2.62619 12.1231 2.60547 12.0939 2.59123C12.0647 2.57698 12.0326 2.56958 12 2.56958C11.9675 2.56958 11.9354 2.57698 11.9062 2.59123C11.8769 2.60547 11.8513 2.62619 11.8313 2.6518L8.83129 6.44733C8.72146 6.5893 8.82057 6.79555 9.00003 6.79555ZM21.8036 15.0536H20.1965C20.0786 15.0536 19.9822 15.15 19.9822 15.2679V19.3929H4.01789V15.2679C4.01789 15.15 3.92146 15.0536 3.80361 15.0536H2.19646C2.07861 15.0536 1.98218 15.15 1.98218 15.2679V20.5714C1.98218 21.0455 2.36521 21.4286 2.83932 21.4286H21.1607C21.6349 21.4286 22.0179 21.0455 22.0179 20.5714V15.2679C22.0179 15.15 21.9215 15.0536 21.8036 15.0536Z" fill="white"/>
//           </svg>

//         </button>
//       </div>
//     </div>

//   )
}

export default WeddingCameraAndroid
import { useEffect } from "react"

interface Props {
  top: number;
  behaviour?: 'instant' | 'smooth';
}

export const ScrollTo = ({top, behaviour = 'smooth'} : Props) => {
  useEffect(() => {
    window.scrollTo({top: top, behavior: behaviour})
  }, [])
}
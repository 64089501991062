import React, { useEffect, useRef, useState } from 'react';
import {  Link, useLocation, useNavigate } from "react-router-dom";
import './index.css'
import HamburgerSvg from '../../atom/hamburgerSvg/index.tsx';
import CloseSvg from '../../atom/closeSvg/index.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store.ts';

interface Props {
  routePrefix?: string;
  scrollToContact: () => void
}

const Navbar : React.FC<Props> = ({routePrefix='', scrollToContact}) => { 
  const { windowWidth } = useSelector((state: RootState) => state)

  const location = useLocation();
  const navigate = useNavigate();

  const navbarDiv = useRef<HTMLDivElement | null>(null)

  const [goToPath, setGoToPath] = useState<string | null>(null)

  const containerRef = useRef<HTMLDivElement>(null)

  const closeNavMenu = async () => {
    const navMenu = document.querySelector('.navbar-menu')
    if(navMenu) {
      navMenu.classList.add('navbar-menu-closed')
      navMenu.classList.remove('navbar-menu-opened')
      const navMenuLinks = document.querySelector('.navbar-menu-content')

      setTimeout(() => {
        if(navMenuLinks) {
          for(let i = 0; i < navMenuLinks.children.length; i++ ) {
            console.log(navMenuLinks.children[i])
            navMenuLinks.children[i].classList.remove('link-nav-menu-visible')
          }
        }
      }, 400)
    }
  }

  const openNavMenu = async () => {
    const navMenu = document.querySelector('.navbar-menu')

    if(navMenu) {
      navMenu.classList.remove('navbar-menu-closed')
      navMenu.classList.add('navbar-menu-opened')

      const navMenuLinks = document.querySelector('.navbar-menu-content')
      navMenu.classList.remove('navbar-menu-hidden')
      navMenu.classList.add('navbar-menu-visible')
  
      const timer = (ms: number) => new Promise(res => setTimeout(res, ms))

      if(navMenuLinks) {
        for(let i = 0; i < navMenuLinks.children.length; i++ ) {
          console.log(navMenuLinks.children[i])
          navMenuLinks.children[i].classList.add('link-nav-menu-visible')
          await timer(200)
        }
      }
    }
  }

  useEffect(() => {
    const navbar = document.querySelector('.navbar-div')

    window.addEventListener('scroll', (e) => {
      let lastKnownScrollPosition = window.scrollY;

      if (lastKnownScrollPosition >= 100 && navbar) {
        navbar.classList.add("sticky");
      } else if(lastKnownScrollPosition <=100 && navbar) {
        navbar.classList.remove("sticky");
      }
     
    })
  }, [])

  const isActive = (pathName: string) => {  
    if(location.pathname === pathName) {
      closeNavMenu()
      window.scrollTo({top: 0, behavior: 'instant'})
      setGoToPath(null)
      
    }
  };

  useEffect(() => {
    if(goToPath) {
      isActive(goToPath)
    }
  }, [goToPath])


  useEffect(() => {
    setTimeout(() => {
      navItemActive(location.pathname)
    }, 20);
  }, [location])

  const navItemActive = (path: string) => {
    switch(path) {
      case '/':
        console.log(document.querySelector('#home-line'))
        document.querySelector('#home-line')?.classList.add('active')
        document.querySelector('#drawer-home')?.classList.add('link-nav-menu-drawer-active')
        break;

      case '/en/':
        console.log(document.querySelector('#home-line'))
        document.querySelector('#home-line')?.classList.add('active')
        document.querySelector('#drawer-home')?.classList.add('link-nav-menu-drawer-active')
        break;

      case '/about':
        document.querySelector('#about-line')?.classList.add('active')
        document.querySelector('#drawer-about')?.classList.add('link-nav-menu-drawer-active')
        break;
     
        case '/en/about':
        document.querySelector('#about-line')?.classList.add('active')
        document.querySelector('#drawer-about')?.classList.add('link-nav-menu-drawer-active')
        break;

      case '/portfolio':
        document.querySelector('#portfolio-line')?.classList.add('active')
        document.querySelector('#drawer-portfolio')?.classList.add('link-nav-menu-drawer-active')
        break;

      case '/en/portfolio':
        document.querySelector('#portfolio-line')?.classList.add('active')
        document.querySelector('#drawer-portfolio')?.classList.add('link-nav-menu-drawer-active')
        break;
    }
  }


  const changeLanguage = () => {
    if(location.pathname.includes('/en')) {
      const newPath = location.pathname.replace('/en', "")
      navigate(newPath)
    } else {
      navigate(`/en${location.pathname}`)
    }
  }

  return (
    <div className='navbar-div'>
      <div ref={containerRef} className='navbar-container container'>
        {
          windowWidth <= 768 ?
          <div className='navbar-container-small'>
            <Link className='navbar-container-logo-small' to={`${routePrefix}/`} onClick={() => setGoToPath('/')}>
              <img src={require('../../../assets/mcode-logo-modern.png')} alt='mcode logo'/>
            </Link>
            <div className='navbar-open-btn'>
              <HamburgerSvg onClick={() => {
                openNavMenu()
              }} />
            </div>
            
            <div className="navbar-menu navbar-menu-closed">
              <div className='nav-menu-logo-container image-top'>
                <img src={require('../../../assets/footer-mcode-logo.png')} alt='mcode logo'/>
              </div>
             
              <div className='nav-menu-logo-container image-bottom'>
                <img src={require('../../../assets/footer-mcode-logo.png')} alt='mcode logo'/>
              </div>
              
              <div className="navbar-menu-close" onClick={() => closeNavMenu()}>
                  <CloseSvg />
              </div>

              <div className="navbar-menu-content">
                <div className="link link-nav-menu">
                  <Link id='drawer-home' to={`${routePrefix}/`} onClick={() => setGoToPath(`${routePrefix}/`)}>{routePrefix.includes('en') ? 'Home': 'Naslovnica'}</Link>
                </div>
                  
                <div className="link link-nav-menu">  
                  <Link id='drawer-about' to={`${routePrefix}/about`} onClick={() => setGoToPath(`${routePrefix}/about`)}>{routePrefix.includes('en') ? 'About us' : 'O nama'}</Link>
                </div>
                
                <div className="link link-nav-menu">
                  <Link id='drawer-portfolio' to={`${routePrefix}/portfolio`} onClick={() => setGoToPath(`${routePrefix}/portfolio`)}>Portfolio</Link>
                </div>

                <div className='link link-nav-menu'>
                  <Link to="" onClick={() => {
                    closeNavMenu()  
                    scrollToContact()
                  }}>{routePrefix.includes('en') ? 'Contact' : "Kontakt"}</Link>
                </div>
                <div className='navbar-language-container link-nav-menu' onClick={() => changeLanguage()}>
                  <p>
                    {routePrefix === '' ? 'EN' : "HR"}
                  </p>
                </div>
              </div>
             </div> 
            </div>
            :
            <div ref={navbarDiv} className='navbar-large'>
              <Link to={`${routePrefix}/`} onClick={() => setGoToPath(`${routePrefix}/`)}>
                <img src={require('../../../assets/mcode-logo-modern.png')} alt='mcode logo'/>
              </Link>
              <div>
                <Link id="home" to={`${routePrefix}/`} onClick={() => setGoToPath(`${routePrefix}/`)}>{routePrefix.includes('en') ? 'Home': 'Naslovnica'}</Link>
                <div id='home-line' className='nav-item-underline' />
              </div>
                
              <div>
                <Link id="about" to={`${routePrefix}/about`} onClick={() => setGoToPath(`${routePrefix}/about`)}>{routePrefix.includes('en') ? 'About us' : 'O nama'}</Link>
                <div id='about-line' className='nav-item-underline' />
              </div>
              
              <div>
                <Link id="portfolio" to={`${routePrefix}/portfolio`} onClick={() => setGoToPath(`${routePrefix}/portfolio`)}>Portfolio</Link>
                <div id='portfolio-line' className='nav-item-underline' />
              </div>
              
              <div>
                <Link to='' onClick={() => scrollToContact()}>{routePrefix.includes('en') ? 'Contact' : "Kontakt"}</Link>
              </div>
            </div>
          }
          
          {
            windowWidth >= 768 &&
            <div className='navbar-language-container' onClick={() => changeLanguage()}>
              <p>
                {routePrefix === '' ? 'EN' : "HR"}
              </p>
            </div>
          }
         
      </div>
    </div>
  )
}

export default Navbar
import './styles/sharedStyles.css';
import './App.css'
import { RouterProvider } from 'react-router-dom';
import { router } from './router/index.tsx';
import {  useEffect } from 'react';
import Lottie from 'react-lottie'
import * as animationData from './assets/animation/mcode-logo-medium-lottie.json'
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderShown, setWindowWidth } from './store/storeSlice.ts';
import ReactGA from "react-ga4";

const defaultOptions = {
  loop: false,
  autoplay: true, 
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function App() {
  ReactGA.initialize("G-4MJKRK76NM");

  const {loaderShown, windowWidth} = useSelector((state) => state) 
  const dispatch = useDispatch();

  const showBodyAfterLoader = () => {
    const bodyContainer = document.querySelector('.body-container')
    const loaderContainer = document.querySelector('.main-loader-container')

    setTimeout(() => {
      if(loaderContainer) {
        loaderContainer.classList.add('opacity-0-ease')
      }
      setTimeout(() => {
        if(bodyContainer) {
          bodyContainer.classList.add('position-initial')
        }
        
        dispatch(setLoaderShown(true))

      }, 500 )
    }, 2600)
  }
  
  useEffect(() => {
    showBodyAfterLoader()

    dispatch(setWindowWidth(window.innerWidth))

    window.addEventListener('resize', () => {
      dispatch(setWindowWidth(window.innerWidth))

    })
  }, [])



  return (
    <>
      <RouterProvider router={router} />
      
      {
        !loaderShown &&
        <div className='main-loader-container' >
          <div className='lottie-loader'>
            <Lottie 
              options={defaultOptions}
              height={window.innerHeight}
              width={window.innerWidth}
              isClickToPauseDisabled={true}
            />
          </div>
        </div>
      }
    </>
  );
}

export default App;

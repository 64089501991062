import React from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
// HR
import HomeHr from "../pages/hr/home/index.tsx";
import AboutUsHr from "../pages/hr/aboutUs/index.tsx";
import PortfolioHr from "../pages/hr/portfolio/index.tsx";
// EN
import HomeEn from "../pages/en/home/index.tsx";
import AboutUsEn from "../pages/en/aboutUs/index.tsx";
import PortfolioEn from "../pages/en/portfolio/index.tsx";
import WeddingCameraAndroid from "../pages/hr/app/android/weddingCamera.tsx";
import WeddingCameraIOS from "../pages/hr/app/ios/weddingCamera.tsx";
import WeddingCameraGallery from "../pages/hr/gallery/index.tsx";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeHr />,
  },
  {
    path: "/about",
    element: <AboutUsHr />,
  },
  {
    path: "/portfolio",
    element: <PortfolioHr />,
  },

  {
    path: "/en",
    element: <HomeEn />,
  },
  {
    path: "/en/about",
    element: <AboutUsEn />,
  },
  {
    path: "/en/portfolio",
    element: <PortfolioEn />,
  },
  {
    path: "/app/android/weddingCamera",
    element: <WeddingCameraAndroid />,
  },
  {
    path: "/app/ios/weddingCamera",
    element: <WeddingCameraIOS />,
  },
  {
    path: "/weddingCamera/gallery/marina&dino-21.09.2024.",
    element: <WeddingCameraGallery />,
  },

]);

 

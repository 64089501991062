import React, { useEffect } from 'react';
import Home from '../../home/index.tsx';


const WeddingCameraIOS : React.FC = () => { 
  useEffect(() => {
    window.location.href = 'https://apps.apple.com/hr/app/wedding-camera/id6608982445'
  }, [])

  return (
    <Home />
  )
}

export default WeddingCameraIOS
import React from 'react';
import './index.css'

const Divider : React.FC = () => { 
  return (
    <div className='divider-container'/>
      
  )
}

export default Divider
  /* Storing user's device details in a variable*/

  export const checkIsMobile = (userAgent: string) => {
    // let details = navigator.userAgent; 
    let regexp = /android|iphone|kindle|ipad|iPhone/i; 

    let isMobileDevice = regexp.test(userAgent); 
    if (isMobileDevice) { 
      return true
    }

    return false
  }
  
import React, { forwardRef } from 'react';
import './index.css'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store.ts';

interface Props {
  imageLeft: boolean
  imageUrl: string
  isLast?: boolean
  title: string | any[]
  subtitle: string | any[];
  children?: JSX.Element
  isProject?: boolean;
  className?: string;

  onClick?: () => void
}

const InfoContent :React.FC<Props> = forwardRef(({
  imageLeft = false,
  imageUrl = require('../../../assets/mobile-image.png'), 
  isLast = false,
  title = ["Stvaramo mobilne aplikacije", <br/>, "po tvojoj mjeri"],
  subtitle = "Zamisli aplikaciju koja prati tvoje svakodnevne korake, organizira tvoje ideje ili pomaže tvojoj maloj firmi da raste. To su upravo vrste aplikacija koje stvaramo - intuitivne, korisne i prilagođene tvojim potrebama.",
  children,
  isProject = false,
  className = '',
  onClick
}, ref) => { 
  const { windowWidth } = useSelector((state: RootState) => state)

  return (
    <div ref={ref} className={`info-container container ${className} ${isProject && onClick ? 'mouse-hover' : ''}`} style={isLast ? {paddingBottom: '180px'}  : {}} onClick={onClick}>
      {
        windowWidth >= 990 ?
        imageLeft ?
        <>
         <div className={`info-image-container image-left ${isProject ? 'info-image-project' : '' }`}>
            <img className='info-image' src={imageUrl} alt="mobile apps"/>
          </div>
          <div className='info-text-container'>
            <h1 className='info-text-title'>
              {title}
            </h1>

            <h2 className='info-text-subtitle'>
              {subtitle}
            </h2>

            {children}
          </div>
        </> :
        <>
          <div className='info-text-container'>
            <h1 className='info-text-title'>
              {title}
            </h1>

            <h2 className='info-text-subtitle'>
              {subtitle}
            </h2>


            {children}
          </div>
          <div className={`info-image-container image-right  ${isProject ? 'info-image-project' : '' }`}>
            <img className='info-image' src={imageUrl} alt="mobile apps"/>
          </div>
        </> : 
        <div className='info-image-container-vertical'>
          <div className='info-image-container image-center'>
            <img className='info-image-center' src={imageUrl} alt="mobile apps"/>
          </div>
          <div className='info-text-container'>
            <h1 className='info-text-title'>
              {title}
            </h1>

            <h2 className='info-text-subtitle'>
              {subtitle}
            </h2>

            {children}
          </div>
        </div> 
      }
     
    </div>
  )
})

export default InfoContent
import React, { useEffect } from 'react';
import Home from '../home/index.tsx';



const WeddingCameraGallery : React.FC = () => { 
  useEffect(() => {
    window.location.href = 'https://photos.google.com/u/1/share/AF1QipPYZChwxnycyPtiHTGiITiKGU8qy62ysP7kZThdbimejEEHn842zGDeVfKmj7G1-w?key=SWRDSlR4R2tNallXWkZyTXB5anNJNWU2azRJUmtB'
  }, [])

  return (
    <Home />
  )
}

export default WeddingCameraGallery
import React from 'react';
import './index.css'
 
interface Props {
  title: string;
  onPress: () => void
}

const BtnPrimary : React.FC<Props> = ({
  title= 'primary',
  onPress= () => {}
}) => { 
  return (
    <>
      <button className="button-primary" onClick={onPress}><p>{title}</p></button>
    </>

    // <div className='btn-primary-container' onClick={}>
    //   <p>{title}</p>
    // </div>
  )
}

export default BtnPrimary

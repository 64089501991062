import React, { useEffect, useRef } from 'react';
import HeroSection from '../../../components/organism/heroSection/index.tsx';
import Navbar from '../../../components/organism/navbar/index.tsx';
import './index.css'
import SendMessage from '../../../components/organism/sendMessage/index.tsx';
import Footer from '../../../components/organism/footer/index.tsx';
import WhoWeAre from '../../../components/organism/whoWeAre/index.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store.ts';
import { ScrollTo } from '../../../hooks/scrollTo.ts';
import { SlideIn } from '../../../hooks/slideIn.ts';

const AboutUs : React.FC = () => { 
  const loaderShown = useSelector((state: RootState) => state.loaderShown)

  const contactRef = useRef<HTMLDivElement>(null)
  const elementsInView = useRef<HTMLDivElement[]>([]);

  ScrollTo({top: 0, behaviour: 'instant'})
  SlideIn({elementsInView: elementsInView})

   const scrollToContact = () => {
    if(contactRef.current !== null) {
      contactRef.current.scrollIntoView(false)
    }
  }    

  useEffect(() => {
    if(loaderShown) {
      document.querySelector('.body-container')?.classList.add('position-initial')
    }
  }, [])


  return (
    <div className='body-container'>
      <Navbar routePrefix={'/en'} scrollToContact={scrollToContact} />
      <HeroSection 
        isHome={false} 
        title='About us' 
        subtitle={`We are a small but passionate team dedicated to designing and developing mobile applications and websites. 
        Our mission is simple - to create digital solutions that shape the future of online experiences. We are visionaries at heart, 
        striving to become a trusted partner who helps our clients achieve their goals. Every project for us is an opportunity to make a 
        positive change and achieve outstanding results for all those we collaborate with.`} />

      <WhoWeAre
       ref={(el: HTMLDivElement) => elementsInView.current[0] = el} 
       className='offset' 
       lang='en' 
       title="Who we are" />

      <SendMessage 
        ref={(el: HTMLDivElement) => elementsInView.current[1] = el} 
        className='offset' 
        lang='en' />

      <Footer 
        className='offset'
        lang='en'
        ref={(el: HTMLDivElement) => {
          contactRef.current = el;
          elementsInView.current[2] = el;
          
        }}/>
    </div>
  )
}

export default AboutUs
import React, { MutableRefObject, forwardRef, useEffect } from 'react';
import './index.css'
import ContactSection from '../../molecule/contactSection/index.tsx';
import Divider from '../../atom/divider/index.tsx';

interface Props {
  lang: string;
  className: string
}
export type Ref = any;

const Footer : React.FC<Props> = forwardRef(({lang = 'hr', className= ''}, ref: any) => { 
  return (
    <div ref={ref} id="contact" className={`footer-container ${className}`}>
      <div className='footer-content-container container'>
        <h2 className='title-md'>{lang ==='hr' ? "Kontakt" : "Contact"}</h2>

        <p className='footer-contact-subtitle'>
          {
            lang === 'hr' ? "Imaš li projekt na umu? Voljeli bismo da nam se javiš!" : "Do you have a project in mind? We would love to hear from you!"
          }
        </p>

        <div className='footer-container-section'>
          <ContactSection lang={lang} title='Email' subtitle={lang ==='hr' ? 'Pošalji nam poruku' : 'Send us a message'} value='hello@mcode.hr'/>
        </div>



        <div className='footer-copyright-container'>
          <Divider />
          <p>
            mcode © 2024
          </p>
        </div>
      </div>

      <img src={require('../../../assets/footer-mcode-logo.png')} alt='mcode logo'/>

     
    </div>
  )
})

export default Footer
import { MutableRefObject, useEffect } from "react";

interface ISlideIn {
  elementsInView: MutableRefObject<HTMLDivElement[]>
}

export const SlideIn = ({elementsInView}: ISlideIn) => {
  useEffect(() => {
    document.addEventListener('scroll', () => {
      if(elementsInView.current) {
        elementsInView.current.forEach((divRef, index) => {
          if (divRef) {
            const rect = divRef.getBoundingClientRect();

            if(window.innerWidth <= 960 && rect.top - 230 < window.innerHeight && rect.bottom >= 0){
              divRef.classList.add('slide-in')
              console.log('element with index ' + index + ' Is in view')
              return
            }

            if(rect.top - 100 < window.innerHeight && rect.bottom >= 0){
              divRef.classList.add('slide-in')
              console.log('element with index ' + index + ' Is in view')
            }
          }
        });
      }
    })
    
  }, [])
}

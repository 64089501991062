import React, { useEffect, useRef } from 'react';
import HeroSection from '../../../components/organism/heroSection/index.tsx';
import Navbar from '../../../components/organism/navbar/index.tsx';
import './index.css'
import SendMessage from '../../../components/organism/sendMessage/index.tsx';
import Footer from '../../../components/organism/footer/index.tsx';
import WhoWeAre from '../../../components/organism/whoWeAre/index.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store.ts';
import { SlideIn } from '../../../hooks/slideIn.ts';
import { ScrollTo } from '../../../hooks/scrollTo.ts';

const AboutUs : React.FC = () => { 
  const loaderShown = useSelector((state: RootState) => state.loaderShown)

  const contactRef = useRef<HTMLDivElement>(null)
  const elementsInView = useRef<HTMLDivElement[]>([]);

  ScrollTo({top: 0, behaviour: 'instant'})
  SlideIn({elementsInView: elementsInView})



   const scrollToContact = () => {
    if(contactRef.current !== null) {
      contactRef.current.scrollIntoView(false)
    }
  }    

  useEffect(() => {
    if(loaderShown) {
      document.querySelector('.body-container')?.classList.add('position-initial')
    }
  }, [])


  return (
    <div className='body-container'>
      <Navbar scrollToContact={scrollToContact} />
      <HeroSection 
        isHome={false} 
        title='O nama' 
        subtitle={`Mi smo mali, ali strastveni tim posvećen dizajniranju i razvoju mobilnih aplikacija i web stranica. Naša misija je jednostavna - stvarati digitalna rješenja koja 
        oblikuju budućnost online iskustva. Vizionari smo u srcu, težimo postati pouzdan 
        partner koji pomaže svojim korisnicima ostvariti ciljeve. Svaki projekt za nas je 
        prilika da napravimo pozitivnu promjenu i ostvarimo izvanredne rezultate za 
        sve s kojima surađujemo.`} />

      <WhoWeAre
        ref={(el: HTMLDivElement) => elementsInView.current[0] = el} 
        className='offset' 
        lang='hr'/>

      <SendMessage  
        ref={(el: HTMLDivElement) => elementsInView.current[1] = el} 
        className='offset' 
        lang='hr'/>

      <Footer 
        className='offset'
        ref={(el: HTMLDivElement) => {
        contactRef.current = el;
        elementsInView.current[2] = el;
        
        }} />
    </div>
  )
}

export default AboutUs
import React, { useEffect, useRef } from 'react';
import HeroSection from '../../../components/organism/heroSection/index.tsx';
import Navbar from '../../../components/organism/navbar/index.tsx';
import InfoContent from '../../../components/organism/infoContent/index.tsx';
import './index.css'
import InfoSecondary from '../../../components/molecule/infoSecondary/index.tsx';
import SendMessage from '../../../components/organism/sendMessage/index.tsx';
import Footer from '../../../components/organism/footer/index.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store.ts';
import { ScrollTo } from '../../../hooks/scrollTo.ts';
import { SlideIn } from '../../../hooks/slideIn.ts';

const Home : React.FC = () => { 
  const { windowWidth, loaderShown} = useSelector((state: RootState) => state)

  const contactRef = useRef<HTMLDivElement>(null)
  const elementsInView = useRef<HTMLDivElement[]>([]);

  ScrollTo({top: 0, behaviour: 'instant'})
  SlideIn({elementsInView: elementsInView})

   const scrollToContact = () => {
    if(contactRef.current !== null) {
      contactRef.current.scrollIntoView(false)
    }
  }    
   // run this function from an event handler or an effect to execute scroll 
   useEffect(() => {
      if(loaderShown) {
        document.querySelector('.body-container')?.classList.add('position-initial')
      }

    }, [])


  return (
    <div className='body-container'>
      <Navbar routePrefix={'/en'} scrollToContact={scrollToContact} />
      <HeroSection 
        title='Every code has a story, every design has a purpose' 
        subtitle={"Bringing your ideas to life in the digital world. Creating websites, mobile applications, and digital products"}/>
      
      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[0] = el}
        className='offset' 
        imageLeft={false} 
        imageUrl={require('../../../assets/mobile-image.png')} 
        title ={ ["Creating custom mobile applications tailored to your needs."]}
        subtitle='Imagine an app that tracks your daily steps, organizes your ideas, or helps your small business grow. Those are exactly the kinds of apps we create - intuitive, useful, and tailored to your needs.'
      > 
        <div className='info-text-info-secondary-container row'>
          <InfoSecondary title='Individual approach' text="Every client is unique, so we tailor our approach to individual needs and goals." />
          
          {windowWidth >= 768 &&  <div className='spacer100' />}
          
          <InfoSecondary title='Innovation' text='We ensure that your application not only follows but also sets standards in the industry.' />
        </div>
      </InfoContent>
      
      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[1] = el}
        className='offset'
        imageLeft={true} 
        imageUrl={require('../../../assets/graphic-design.png')} 
        title={'Offering graphic design services'}
        subtitle='Dedicated to creating visual stories that capture attention and stand out in the crowd. We understand the importance of the first impression and want to help you make a strong and positive impact on your target market. Because your success is our inspiration.'
        > 
        <div className='info-text-info-secondary-container row'>
          <ul className='info-content-list-item'>
            <li>
              Creating visual identity
            </li>
            <li>
              Helping you stand out in the sea of competition
            </li>
            <li>
              Analyzing trends and create designs that align with your desires and goals
            </li>
          </ul>
        </div>  
      </InfoContent>
      
      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[2] = el}
        className='offset' 
        isLast 
        imageLeft={false} 
        imageUrl={require('../../../assets/website.png')} 
        title={'Crafting websites that shape your digital narrative.'}  
        subtitle='Your website is not just digital presence - it`s your story, your voice on the internet. Through our website development service, we shape your online narrative to reflect your unique values and goals.'
      >
        {/* <div className='info-text-info-secondary-container row'>
          <InfoSecondary title='Predlošci web stranica' text="Istražite našu kolekciju unaprijed dizajniranih predložaka web stranica koji su spremni za personalizaciju prema vašim jedinstvenim potrebama i stilu." />
        </div> */}
      </InfoContent>

      {/* <Reviews /> */}

      <SendMessage 
        className='offset' 
        ref={(el: HTMLDivElement) => elementsInView.current[3] = el}
        lang='en' />

      <Footer 
        lang='en'  
        className='offset' 
        ref={(el: HTMLDivElement) => {
          elementsInView.current[4] = el;
          
          // @ts-ignore
          contactRef.current = el
        }}/>
    </div>
  )
}

export default Home
import React, { forwardRef } from 'react';
import './index.css'
import BtnSecondary from '../../atom/btn-secondary/index.tsx';
import BtnPrimary from '../../atom/btn-primary/index.tsx';
import { checkIsMobile } from '../../../helpers/index.tsx';
import { useLocation, useNavigate,  } from 'react-router-dom';

interface Props {
  lang: string;
  className: string;
}

const SendMessage : React.FC<Props> = forwardRef(({lang = 'hr', className = ''}, ref) => { 

  const navigation = useNavigate()
  const location = useLocation()

  return (
   <div ref={ref} className={`send-message-container ${className}`}>
    <div className='container send-message-content'>
      <h3 className='title-md'>
        {
          lang ==='hr' ? 'Pošalji nam poruku' : 'Send us a message'
        }
        
      </h3>

      <p>
        {
            lang ==='hr' ? 'Pogledaj naš portfolio radova i javi nam se za suradnju!' : 'Check out our portfolio of work and get in touch with us for collaboration!'
        }
      </p>

      <div className='send-message-btns-container'>
        <BtnPrimary title={lang ==='hr' ? 'Konzultacije' : 'Consultations'} onPress={() => {
          if(checkIsMobile(navigator.userAgent)) {
            const sendMailButton : any = document.querySelector('#send-mail');
            if (sendMailButton) {
                sendMailButton.click();
            }
          } else {
            lang ==='hr' ? window.open('https://mail.google.com/mail/?view=cm&fs=1&to=hello@mcode.hr&su=Upit&body=Pozdrav, \nZanima me ..') : window.open('https://mail.google.com/mail/?view=cm&fs=1&to=hello@mcode.hr&su=Inquiry&body=Hello, \nWe would like to ..')
          }
        }} />
        <div className='spacer20'/>
        
        <BtnSecondary title='Portfolio' onPress={() => {
          if(location.pathname === '/portfolio' || location.pathname === '/en/portfolio') {
            window.scrollTo({top: 0})
          } else {
            if(lang === 'hr') {
              navigation('/portfolio')
            } else {
              navigation('/en/portfolio')
            }
          }
        }} />

        <a id='send-mail' style={{visibility: 'hidden'}} href={lang ==='hr' ? 'mailto:hello@mcode.hr?su=Upit&body=Pozdrav, \nZanima me ..' : 'mailto:hello@mcode.hr?su=Inquiry&body=Hello, \nWe would like to ..'} target='_blank' rel="noreferrer">.</a>
      </div>
    </div>
   </div>
  )
})

export default SendMessage
import React, { forwardRef } from 'react';
import AboutEmployee from '../../molecule/aboutEmployee/index.tsx';
import './index.css'
import { ABOUT_ANTONIJA, ABOUT_ANTONIJA_EN, ABOUT_MATKO, ABOUT_MATKO_EN } from '../../../constants/index.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store.ts';

interface Props {
  lang: string;
  title?: string;
  className?: string;
}

const WhoWeAre : React.FC<Props> = forwardRef(({title = 'Tko smo mi',lang = 'hr', className =''}, ref) => { 
  const { windowWidth, loaderShown} = useSelector((state: RootState) => state)
  

  return (
    <div ref={ref} className={`container who-we-are-container ${className}`}>
      <h3 className='title-md text-center who-we-are-title'>{title}</h3>
      <div className='about-employee-row row gx-5 gy-5'>
        <AboutEmployee web='https://matkosetnik.com/en' img={require('../../../assets/profile-coming-soon.png')} name='Matko Setnik' text={lang === 'hr' ? ABOUT_MATKO : ABOUT_MATKO_EN }/>
        {
          windowWidth < 786 && <div style={{height: 80}} />
        }
        <AboutEmployee web='https://antonijaluketic.com' img={require('../../../assets/profile-coming-soon.png')} name='Antonija Luketić' text={lang ==='hr' ? ABOUT_ANTONIJA : ABOUT_ANTONIJA_EN}/>
      </div>
    </div>
  )
})

export default WhoWeAre
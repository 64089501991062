import { createSlice } from "@reduxjs/toolkit";

interface IStoreState {
  windowWidth: number;
  loaderShown: boolean;
}

const initialState: IStoreState = {
  windowWidth: 0,
  loaderShown: false
}

const storeSlice = createSlice({
  name: 'store',
  initialState: initialState,
  reducers: {
    setWindowWidth: (state, action) => {
      state.windowWidth = action.payload
    },
    setLoaderShown: (state, action) => {
      state.loaderShown = action.payload
    }
  }
})

export const {setWindowWidth, setLoaderShown} = storeSlice.actions;
export default storeSlice.reducer;
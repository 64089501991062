import React from 'react';
import './index.css'

interface Props {
  img: string;
  name: string;
  text: string;
  web: string
}

const AboutEmployee : React.FC<Props> = ({
  img,
  name,
  text,
  web
}) => { 
  return (
    <div className='about-employee-container col col-xs-12 col-md-6 col-sm-12 col-12 text-center'>
      <img className='about-employee-image' src={img} alt='name' />
      <div className='about-employee-text-container'>
        <a className='about-employee-text-href' href={web} target='_blank'>
          <h3 className='subtitle'>
            {name}
          </h3>
        </a>
        <p>
          {text}
        </p>
      </div>
    </div>
  )
}

export default AboutEmployee